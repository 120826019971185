// declare package-global styles here
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500&display=swap");

html {
  scroll-padding-top: calc(64px + 22px);
}

// HACK: .MuiListItem-container doesn't seem to be able to be targeted
// with the sx prop so for now we'll need to target it using CSS
.MuiListItem-container:has(.log-entry-item) {
  width: 100%;
}
